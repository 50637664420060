import React from 'react'

import { useLocaleRedirection } from '@arch-log/webapp.lib/react.hook'

/**
 */
const Page = ({ id }) => {
  useLocaleRedirection(`/${id}/boards`)

  return null
}

export default Page
